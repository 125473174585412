import { useRef, useEffect } from "react";
import { Chart, registerables } from 'chart.js';

function UsersGraph () {
    const canvasDom = useRef(null);

    useEffect(() => {
      const ctx = canvasDom.current.getContext("2d");
      console.log(ctx);
      Chart.register(...registerables);

      new Chart(ctx, {
        type: "bar",
        data: {
          labels: ["1월", "2월", "3월", "4월", "5월", "6월", "7월", "8월", "9월", "10월", "11월", "12월"],
          datasets: [
            {
              label: "월별 접속횟수",
              data: [1200, 1007, 989, 1168, 1202, 1145],
              backgroundColor: 'rgba(54, 162, 235, 0.4)',
            },
          ],
        },
      });
    }, []);

    return (
      <div>
        <canvas ref={canvasDom} style={{height:'40vh', width:'80vw'}}></canvas>
      </div>        
    )
}

export default UsersGraph;