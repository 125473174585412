import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'antd';

import { useRecoilState, useRecoilValue } from 'recoil';
import { getLoginState, loginState, userState } from '../../store/user-store.js';

import { apiCall } from '../../common-lib/custom-axios';


function AuthCheck(props) {

    const [{isLogin, isAdmin, isSession}, setLoginState] = useRecoilState(loginState);
    const [{ 
        userId,
        userName,
        userArea,
        userOfficePosition,
    }, setUserState] = useRecoilState(userState);
    
    const SSID = sessionStorage.getItem('SSID');

    let navigate = useNavigate();

    useEffect(() => {

        resetLoginInfo();

    }, [])

    const resetLoginInfo = async () => {

        if(SSID && !isSession) {
            const request = await apiCall.get('/auth').then( res => res.data );

            console.log(request);
            
            if(request.loginSuccess) {
                // recoil 상태변수에 상태값들을 저장한다.
                setLoginState({isLogin : request.loginSuccess, isAdmin: request.isAdmin, isSession : SSID});
                setUserState({
                    userId : request.userId,
                    userName : request.userName,
                    userArea : request.userArea,
                    userOfficePosition : request.userOfficePosition,
                });
            }
            else {
                Modal.error({
                    title: '접속시간 만료!',
                    content: '접속시간이 만료되어 로그인 페이지로 이동합니다.', 
                    onOk() { 
                        sessionStorage.clear();
                        navigate('/login');
                    },
                });
            }
    
            // console.log(request)
            // console.log(request.userId)
            // console.log(request.userArea)
        }
        else {
            if(props.isLogin !== isLogin || props.isAdmin !== isAdmin || !isSession)
                navigate('/login');
        }

        console.log('isLogin :', isLogin);
    }

    if(props.isLogin === isLogin && props.isAdmin === isAdmin)
        return React.cloneElement(props.children, {...props});
    else 
        return null;
}
export default AuthCheck;