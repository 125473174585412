import { useEffect, useState } from 'react';
import { Row, Col, Statistic, Card, PageHeader, Drawer } from 'antd';
import { UserOutlined, UserSwitchOutlined, DatabaseOutlined } from '@ant-design/icons';
import { Divider } from "@blueprintjs/core";
import '@blueprintjs/core/lib/css/blueprint.css';

import { apiCall } from '../../../common-lib/custom-axios';

import UsersGraph from '../../../charts/usersChart';
import TotalUsersList from '../dashboard/totaluserslist';
import CurrentUsersList from '../dashboard/currentuserslist';
import TodayUsersList from '../dashboard/todayuserslist';
import PCWebUsersList from '../dashboard/pcwebuserslist';
import MobileUsersList from '../dashboard/mobileuserslist';
import FailedUsersList from '../dashboard/faileduserslist';
import '../../../css/card.css';

function Dashboard() {

    const [ showDrawer, setShowDrawer ] = useState(false);
    const [ drawerTitle, setDrawerTitle ] = useState('');

    const [ usersSituation, setUsersSituation ] = useState({});

    let drawerContent = null;
    
    useEffect(() => {

        getUsersSituation();

    }, [])

    const getUsersSituation = async () => {
        const res = await apiCall.get('/dashboard/users').then(res => res.data);
        console.log(res);
        setUsersSituation(res.data);
        console.log(usersSituation);
    }

    const title1 = (
        <>
            <UserOutlined /> 사용자 현황
        </>
    )

    const title2 = (
        <>
            <DatabaseOutlined /> 데이터 가공 현황
        </>
    )

    const showDrawerToggle = (title) => {
        setDrawerTitle(title);
        showDrawer ? setShowDrawer(false) : setShowDrawer(true);
    }

    return (
        <>
            <PageHeader className="site-page-header" title={title1} subTitle="사용자 현황을 보여줍니다.(관리자모드 포함)"
                        extra={[
                        ]} ></PageHeader>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col span={12}>
                    <Row gutter={12}>
                        <Col span={8}>
                            <div onClick={() => showDrawerToggle('총 사용자 보기')}>
                            <Card className='intraCard' hoverable style={{width:'100%'}}>
                                <Statistic  className='intraCard' title="총 사용자" value={usersSituation.totalUsers} valueStyle={{color:'white'}} prefix={<UserSwitchOutlined />} suffix="명" />
                            </Card>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div onClick={() => showDrawerToggle('현재 접속자 보기')}>
                            <Card className='intraCard' hoverable style={{width:'100%'}}>
                            <Statistic title="현재 접속자" value={usersSituation.connectUsers} valueStyle={{color:'white'}} prefix={<UserSwitchOutlined />} suffix="건" />
                            </Card>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div onClick={() => showDrawerToggle('일일 접속자 보기')}> 
                            <Card className='intraCard' hoverable style={{width:'100%'}}>
                            <Statistic title="일일 접속자" value={usersSituation.todayUsers} valueStyle={{color:'white'}} prefix={<UserSwitchOutlined />} suffix="명" />
                            </Card>
                            </div>
                        </Col>
                    </Row><br/>
                    <Row gutter={12}>
                    <Col span={8}>
                            <div onClick={() => showDrawerToggle('PC웹에서 접속')}> 
                            <Card className='intraCard' hoverable style={{width:'100%'}}>
                                <Statistic title="PC웹에서 접속" value={usersSituation.webUsers} valueStyle={{color:'white'}} suffix="건" />
                            </Card>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div onClick={() => showDrawerToggle('모바일 / 태블릿 접속')}> 
                            <Card className='intraCard' hoverable style={{width:'100%'}}>
                                <Statistic title="모바일 / 태블릿 접속" value={usersSituation.mobileUsers} valueStyle={{color:'white'}} suffix="건" />
                            </Card>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div onClick={() => showDrawerToggle('불량 접속시도 (주간)')}>
                            <Card className='intraCardWarning' hoverable style={{width:'100%'}}>
                                <Statistic title="불량 접속시도 (주간)" value={usersSituation.failedUsers} valueStyle={{color:'white'}} suffix="건" />
                            </Card>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col span={12}>
                    <Card style={{borderRadius:10}}>
                        <UsersGraph />
                    </Card>
                </Col>
            </Row>
            <Divider />
            <PageHeader className="site-page-header" title={title2} subTitle="데이터 변동에 관한 자료를 보여줍니다.(관리자모드 제외)"
                        extra={[
                        ]} ></PageHeader>
            <Row gutter={24} justify='center'>
                <Col span={4}>
                    <Card className='intraCard' hoverable>
                        <Statistic title="7일간 가공 데이터" />
                    </Card>
                </Col>
                <Col span={4}>
                    <Card className='intraCard' hoverable>
                        <Statistic title="가공된 총 데이터" />
                    </Card>
                </Col>
                <Col span={4}>
                    <Card className='intraCard' hoverable>
                        <Statistic title="생성된 데이터수" />
                    </Card>
                </Col>
                <Col span={4}>
                    <Card className='intraCard' hoverable>
                        <Statistic title="수정된 데이터 수" />
                    </Card>
                </Col>
                <Col span={4}>
                    <Card className='intraCardWarning' hoverable>
                        <Statistic title="삭제된 데이터 수" />
                    </Card>
                </Col>
                <Col span={4}>
                    <Card className='intraCardWarning' hoverable>
                        <Statistic title="데이터 가공 실패 수" />
                    </Card>
                </Col>
            </Row>

            <Drawer className="mycustom" title={drawerTitle} visible={showDrawer} onClose={() => showDrawerToggle('')} size="large"
                  placement='right' >
                { drawerTitle == '총 사용자 보기' && <TotalUsersList /> }
                { drawerTitle == '현재 접속자 보기' && <CurrentUsersList /> }
                { drawerTitle == '일일 접속자 보기' && <TodayUsersList />}
                { drawerTitle == 'PC웹에서 접속' && <PCWebUsersList />}
                { drawerTitle == '모바일 / 태블릿 접속' && <MobileUsersList />}
                { drawerTitle == '불량 접속시도 (주간)' && <FailedUsersList /> }
            </Drawer>
        </>
    );
}
export default Dashboard;