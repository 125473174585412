import { useEffect, useState } from 'react';
import { Table, PageHeader } from 'antd';

import { apiCall } from '../../../common-lib/custom-axios';

import { failedUsersList_columns } from '../tableDatas/dashboardDatas';


const FailedUsersList = () => {

    const [data, setData] = useState([]);

    useEffect(() => {
        getFailedUsersList();
    }, []);

    const getFailedUsersList = async () => {
        const res = await apiCall('/dashboard/faileduserslist').then( res => res.data );
        setData(res.data);
        console.log(res);
    
        // 데이터가 없을때 처리
    }

    return (
        <>
            <PageHeader className="site-page-header" title="주간 불량 접속시도" subTitle="단순 입력 실수 일수도 있습니다. 아이피 확인 등 필요" />
            <Table dataSource={data} columns={failedUsersList_columns} size="small" className='intraTable' 
                   bordered={true} pagination={{ showSizeChanger: false, pageSize: 12 }}/>
        </>
    )
}
export default FailedUsersList;