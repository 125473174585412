import { useEffect, useState } from 'react';
import { Table, PageHeader } from 'antd';

import { apiCall } from '../../../common-lib/custom-axios';

import { PCWebUsersList_columns } from '../tableDatas/dashboardDatas';


const MobileUsersList = () => {

    const [data, setData] = useState([]);

    useEffect(() => {
        getMobileUsersList();
    }, []);

    const getMobileUsersList = async () => {
        const res = await apiCall('/dashboard/mobileuserslist').then( res => res.data );
        setData(res.data);
        console.log(res);
    
        // 데이터가 없을때 처리
    }

    return (
        <>
            <PageHeader className="site-page-header" title="핸드폰 혹은 태블릿에서 접속" subTitle="로그인에 성공한 사용자만 나옵니다." />
            <Table dataSource={data} columns={PCWebUsersList_columns} size="small" className='intraTable' 
                   bordered={true} pagination={{ showSizeChanger: false, pageSize: 12 }}/>
        </>
    )
}
export default MobileUsersList;