export const totalUsersList_columns = [
    {
        title: '아이디',
        dataIndex: 'userId',
        align: 'left',
        key: 'userId',
    },
    {
        title: '직위',
        dataIndex: 'postPosition',
        align: 'center',
        key: 'postPosition',
    },
    {
        title: '이름',
        dataIndex: 'userName',
        align: 'center',
        key: 'userName',
    },
    {
        title: '소속부서',
        dataIndex: 'department',
        align: 'center',
        key: 'department',
    },
    {
        title: '관리구역',
        dataIndex: 'territory',
        align: 'center',
        key: 'territory',
    },
    {
        title: '핸드폰',
        dataIndex: 'mobile',
        align: 'center',
        key: 'mobile',
    }
];

export const PCWebUsersList_columns = [
    {
        title: 'ID',
        dataIndex: 'id',
        align: 'center',
        key: 'id',
    },
    {
        title: '접속일자',
        dataIndex: 'logDate',
        align: 'center',
        key: 'logDate',
    },
    {
        title: '접속아이디',
        dataIndex: 'userId',
        align: 'left',
        key: 'userId',
    },
    {
        title: '사용자이름',
        dataIndex: 'userName',
        align: 'center',
        key: 'userName',
    },
    {
        title: '접속자 아이피',
        dataIndex: 'remoteIP',
        align: 'center',
        key: 'remoteIP',
    }
];

export const failedUsersList_columns = [
    {
        title: 'ID',
        dataIndex: 'id',
        align: 'center',
        key: 'id',
    },
    {
        title: '접속시도 일자',
        dataIndex: 'logDate',
        align: 'center',
        key: 'logDate',
    },
    {
        title: '접속시도 아이디',
        dataIndex: 'userId',
        align: 'left',
        key: 'userId',
    },
    {
        title: '사용자이름',
        dataIndex: 'userName',
        align: 'center',
        key: 'userName',
    },
    {
        title: '접속시도 아이피',
        dataIndex: 'remoteIP',
        align: 'center',
        key: 'remoteIP',
    }
];

