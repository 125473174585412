import React, { useEffect, useState } from 'react';
import { useRoutes, Link, Outlet, useParams, useLinkClickHandler } from 'react-router-dom';
import { Layout, Menu, Avatar, Dropdown, Space, Row, Typography } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import 'antd/dist/antd.css';

//import Logout from './logout.js';
import { useRecoilState, useRecoilValue } from 'recoil';
import { getLoginState, loginState, userState } from '../store/user-store.js';

function ScreenLayout() {

    const { SubMenu } = Menu;
    const { Header, Footer, Content, Sider } = Layout;
    const profileMenu = (
        <Menu>
            <Menu.Item key="99"><Link to="/logout">로그아웃</Link></Menu.Item>
        </Menu>
    );

    const { 
        userId,
        userName,
        userArea,
        userOfficePosition,
    } = useRecoilValue(userState);

    let savedIndex = sessionStorage.getItem('menuIndex') ? sessionStorage.getItem('menuIndex'): '1';

    useEffect(() => {
        if(savedIndex == null) {
            savedIndex = '1';
        }
    }, [])

    const clickHandler = (e) => {
        sessionStorage.setItem('menuIndex', e.key)
    }

    return (
        <Layout style={{minHeight: '100vh'}}>
            {/* <Row style={{width:'100%'}}  justify="space-between"> */}
            <Header className="header"  style={{ position: 'fixed', zIndex: 1, width: '100%', backgroundColor: '#fff'}}>
                <div style={{float:'right'}}>
                <div style={{float:'left'}}>{userName}님 환영합니다! &nbsp;&nbsp;</div>
                <Dropdown overlay={profileMenu} placement="bottomRight" arrow overlayStyle={{margin: '-10px -10px 0px 0'}}>
                    <Avatar size={40} icon={<UserOutlined />} style={{ margin: '10px 24px 16px 0', cursor: 'pointer', backgroundColor: '#fa8c16' }} />
                </Dropdown>
                
                </div>
                <Menu theme="white" mode="horizontal" onClick={clickHandler} selectable={true} defaultSelectedKeys={savedIndex}>
                  <Menu.Item key="1"><Link to="/">관리자홈</Link></Menu.Item>
                  <Menu.Item key="5"><Link to="/users">사용자관리</Link></Menu.Item>
                  <Menu.Item key="2"><Link to="/usergroups">그룹관리</Link></Menu.Item>
                  <Menu.Item key="3"><Link to="/menus">메뉴관리</Link></Menu.Item>
                  <Menu.Item key="4"><Link to="/menupermission">메뉴권한관리</Link></Menu.Item>
                    <Menu.SubMenu key="6" title="기초설정">
                        <Menu.Item key="6-1"><Link to='/salesclients'>매출 거래처 관리</Link></Menu.Item>
                        <Menu.Item key="6-2"><Link to='/buyclients'>매입 거래처 관리</Link></Menu.Item>
                        <Menu.Item key="6-3"><Link to='/areas'>거래구역 관리</Link></Menu.Item>
                    </Menu.SubMenu>
                  <Menu.Item key="7"><Link to="/userlogs">사용자 로그관리</Link></Menu.Item>
                </Menu>
            </Header>
            {/* </Row> */}
            <Layout>
                <Content style={{ padding: '0 50px', marginTop: 64}}>
                    <div className="main-panel" style={{ padding: 24, marginTop: 32, backgroundColor: '#fff', minHeight: '82vh'}}>
                        <Outlet />
                    </div>
                </Content>
                <Footer style={{ textAlign: 'center' }}>(유)일신물산 ©2022 Created & Copyright by 고영빈</Footer>
            </Layout>
        </Layout>
    );
}

export default ScreenLayout;