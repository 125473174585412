class idleTimer {
    constructor({timeout, onTimeout, onExpired}) {
        this.timeout = timeout;
        this.onTimeout = onTimeout;

        const expiredTime = parseInt(sessionStorage.getItem('_expiredTime') || 0, 10);
        if(expiredTime > 0 && expiredTime < Date.now()) {
            onExpired();
            return;
        }

        this.eventHandler = this.updateExpiredTime.bind(this);
        this.tracker();
        this.startInterval();
    }

    startInterval() {
        this.updateExpiredTime();

        this.interval = setInterval(() => {
            const expiredTime = parseInt(sessionStorage.getItem("_expiredTime") || 0, 10);

            if(expiredTime < Date.now()) {  // 해제시간 보다 현재시간이 클때
                if(this.onTimeout) {
                    this.onTimeout();
                    this.cleanUp();
                }
            }
        }, 5000);
    }

    updateExpiredTime() {
        if(this.timeoutTracker) {
            clearTimeout(this.timeoutTracker);
        }

        this.timeoutTracker = setTimeout(() => {
            sessionStorage.setItem("_expiredTime", Date.now() + this.timeout * 1000);
        }, 300);
    }

    tracker() {
        window.addEventListener("mousemove", this.eventHandler);
        window.addEventListener("keydown", this.eventHandler);
        window.addEventListener("scroll", this.eventHandler);
        window.addEventListener("wheel", this.eventHandler);
    }

    cleanUp() {
        sessionStorage.removeItem('_expiredTime');
        clearInterval(this.interval);
        window.removeEventListener("mousemove", this.eventHandler);
        window.removeEventListener("keydown", this.eventHandler);
        window.removeEventListener("scroll", this.eventHandler);
        window.removeEventListener("wheel", this.eventHandler);
    }
}

export default idleTimer;