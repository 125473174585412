import { useEffect, useState } from 'react';
import { Table, PageHeader } from 'antd';

import { apiCall } from '../../../common-lib/custom-axios';

import { totalUsersList_columns } from '../tableDatas/dashboardDatas';


const TotalUsersList = () => {

    const [data, setData] = useState([]);

    useEffect(() => {
        getTotalUsersList();
    }, []);

    const getTotalUsersList = async () => {
        const res = await apiCall('/dashboard/totaluserslist').then( res => res.data );
        setData(res.data);
        console.log(res);

        // 데이터가 없을때 처리
    }

    return (
        <>
            <PageHeader className="site-page-header" title="모든 정상 사용자 보기" subTitle="사용가능한 사용자 전부" />
            <Table dataSource={data} columns={totalUsersList_columns} size="small" className='intraTable' 
                   bordered={true} pagination={false}/>
        </>
    )
}
export default TotalUsersList;