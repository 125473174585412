import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Tabs, Table, PageHeader, Row, Modal } from 'antd';
import { AppstoreFilled } from '@ant-design/icons';

import { useRecoilState, useRecoilValue } from 'recoil';
import { getLoginState, loginState, userState } from '../../store/user-store.js';

import { apiCall } from '../../common-lib/custom-axios';

import { login_log_columns, logs_columns } from './tableDatas/tabledatas';
import './../../css/intratable.css'

function UserLogs() {

    const { TabPane } = Tabs;

    let navigate = useNavigate();

    const [{isLogin, isAdmin, isSession}, setLoginState] = useRecoilState(loginState);
    const [{ 
            userId,
            userName,
            userArea,
            userOfficePosition,
           }, setUserState] = useRecoilState(userState);

    const [ mode, setMode ] = useState('로그인 관리');
    const [ loginLogData, setLoginLogData ] = useState([]);
    const [ logData, setLogData ] = useState([]);
    const [ loading, setLoading ] = useState(false);

    useEffect(() =>{

        setLoading(true);
        getLoginLogData('login');
        setLoading(false);

    }, []);

    const getLoginLogData = async (value) => {
       const res = await apiCall.get('/userlogs', { params : {'mode' : value}}).then(res => res.data);

        if(res.isSuccess ) {
          console.log(res.data);
          if(value === 'login') {
            setLoginLogData(res.data);
          }
          else {
            console.log('어드민 실행')
            setLogData(res.data);
          }
        }
        else {

          setLogData([]);
            // Modal.error({
            //   title: '사용자 인증 실패 !',
            //   content: '사용자 인증에 실패했습니다. 접속시간이 만료되었거나 잘못된 경로로 접근하셨습니다. 로그인 페이지로 이동합니다.', 
            //   onOk() { 
            //     sessionStorage.clear();
            //     navigate('/login') 
            //   },
            // });
        }
    }

    const TitleHead = (
      <>
      <Row justify='center' align='middle'><AppstoreFilled />&nbsp;<div>{mode}</div></Row>
      </>
    );

    const checkHandle = (e) => {
      console.log(e)
      if(e === '1'){
        setMode('로그인 로그 관리');
        getLoginLogData('login')
      }
      else if(e === '2') {
        setMode('로그인 로그 관리');
        getLoginLogData('admin')
      }
      else if(e === '3') {
        setMode('로그인 로그 관리');
        getLoginLogData('web')
      }
      else if(e === '4') {
        setMode('모바일 웹 로그인 관리')
        getLoginLogData('mobile')
      }
      else if(e === '5') {
        setMode('모바일 앱 로그인 관리')
        getLoginLogData('app')
      }
    }

    return isLogin ? (
        <>
          <Tabs tabPosition='top' onChange={checkHandle}>
            <TabPane tab="로그인 로그" key="1">
                <PageHeader className="site-page-header" title={TitleHead} subTitle="사용자들이 로그인한 기록을 관리합니다." />
                <Table loading={loading} dataSource={loginLogData} columns={login_log_columns} size="small" className='intraTable'
                       bordered={true} pagination={{ showSizeChanger: false, pageSize: 12 }}/>
            </TabPane>
            <TabPane tab="관리자 로그인 로그" key="2">
                <PageHeader className="site-page-header" title={TitleHead} subTitle="관리자 화면으로 로그인한 기록을 관리합니다." />
                <Table dataSource={logData} columns={logs_columns} size="small" className='intraTable' 
                       bordered={true} pagination={{ showSizeChanger: false, pageSize: 12 }} />
            </TabPane>
            <TabPane tab="웹 로그인 로그" key="3">
                <PageHeader className="site-page-header" title={TitleHead} subTitle="데스크탑에서 웹 화면으로 로그인한 기록을 관리합니다." />
                <Table dataSource={logData} columns={logs_columns} size="small" className='intraTable' 
                       bordered={true} pagination={{ showSizeChanger: false, pageSize: 12 }}/>
            </TabPane>
            <TabPane tab="모바일 웹 로그인 로그" key="4">
                <PageHeader className="site-page-header" title={TitleHead} subTitle="모바일에서 웹 화면으로 로그인한 기록을 관리합니다." />
                <Table dataSource={logData} columns={logs_columns} size="small" className='intraTable' 
                       bordered={true} pagination={{ showSizeChanger: false, pageSize: 12 }}/>
            </TabPane>
            <TabPane tab="모바일 앱 로그인 로그" key="5">
                <PageHeader className="site-page-header" title={TitleHead} subTitle="모바일 전용 프로그램으로 로그인한 기록을 관리합니다." />
                <Table dataSource={logData} columns={logs_columns} size="small" className='intraTable' 
                       bordered={true} pagination={{ showSizeChanger: false, pageSize: 12 }}/>
            </TabPane>
          </Tabs>
        </>
    ) : null;
}

export default UserLogs;