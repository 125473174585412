import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table, PageHeader, Row, Modal } from 'antd';
import { AppstoreFilled } from '@ant-design/icons';

import { useRecoilState, useRecoilValue } from 'recoil';
import { getLoginState, loginState, userState } from '../../store/user-store.js';

import { apiCall } from '../../common-lib/custom-axios';

import { groups_columns } from './tableDatas/tabledatas';
import './../../css/intratable.css';

const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
    getCheckboxProps: (record) => ({
      //disabled: record. === 'Disabled User',
      // Column configuration not to be checked
      //name: record.exePermission,
      checked: record.exePermission
    }),
};

function UserGroups() {

    let navigate = useNavigate();

    const [{isLogin, isAdmin, isSession}, setLoginState] = useRecoilState(loginState);
    const [{ 
        userId,
        userName,
        userArea,
        userOfficePosition,
    }, setUserState] = useRecoilState(userState);

    const [selectionType, setSelectionType] = useState('checkbox');

    const [ groupsData, setGroupsData ] = useState([]);

    const TitleHead = (
        <>
        <Row justify='center' align='middle'><AppstoreFilled />&nbsp;<div>그룹관리</div></Row>
        </>
    );

    useEffect(() =>{

        getGroups();

    }, []);

    const getGroups = async () => {
       const res = await apiCall.get('/groups').then(res => res.data);

        console.log(res);

        if(res.loginSuccess ) {
          console.log(res.data);
          setGroupsData(res.data);
        }
        else {
            Modal.error({
              title: '사용자 인증 실패 !',
              content: '사용자 인증에 실패했습니다. 접속시간이 만료되었거나 잘못된 경로로 접근하셨습니다. 로그인 페이지로 이동합니다.', 
              onOk() { 
                sessionStorage.clear();
                navigate('/login') 
              },
            });
        }
    }

    return  isLogin ? (
        <>
            <div className='user-group'>
                <PageHeader className="site-page-header" title={TitleHead} subTitle="사용자들을 그룹으로 관리합니다." />
                <Table dataSource={groupsData} columns={groups_columns} size="small" className='intraTable' bordered={true} pagination={false} 
                       />
            </div>
        </>) : null;
}
export default UserGroups;