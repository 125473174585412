import { atom, selector } from 'recoil';

export const loginState = atom({
    key : 'loginState',
    default : {
        isLogin : false,
        isAdmin : false,
        isSession : false,
    }
})

export const getLoginState = selector({
    key : 'getLoginState',
    get : ({get}) => {
        return get(loginState);
    }
})

export const userState = atom({
    key : 'userState',
    default: {
        userId  : '',
        userName : '',
        userArea : '',
        userOfficePosition : '',
    }
});

export const getUserState = selector({
   key : 'getUserState',
   get : ({get}) => {
       return get(userState);
   }
});