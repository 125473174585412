import React, { useState } from 'react';
import { Typography, Upload, Button, Divider, Table } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

import { useRecoilState, useRecoilValue } from 'recoil';
import { getLoginState, loginState, userState } from '../../store/user-store.js';
import { apiCall, imageApiCall } from '../../common-lib/custom-axios.js';

import { sales_clients_columns } from './tableDatas/salesDatas';

const { Text } = Typography;

function SalesClients() {

    const [{isLogin, isAdmin, isSession}, setLoginState] = useRecoilState(loginState);
    const [{ 
        userId,
        userName,
        userArea,
        userOfficePosition,
    }, setUserState] = useRecoilState(userState);


    const [ fileList, setFileList ] = useState();
    const [ data, setData ] = useState([]);
    const [ loading, setLoading ] = useState(false);

    const props = {
        action: 'https://images.eilsin.co.kr/fileupload',
        // headers: {
        //     'Content-Type': 'multipart/form-data;'
        //   },

        onChange({ file, fileList }) {
            if (file.status !== 'uploading') {
              console.log(file, fileList);
              setFileList(fileList);
            }
        },

        defaultFileList: [
        ],
    };

    const getConvertFile = async () => {
        setLoading(true);
        const res = await imageApiCall.get('/convertfile', {params : {file: fileList[0].name}}).then( res => res.data );
        //console.log(res);
        setData(res);
        setLoading(false);
    }

    const writeDB = async () => {
        setLoading(true);
        const res = await apiCall.post('/writedb/salesclients', {data}).then( res => res.data )
        console.log(res);
        setLoading(false);
    }

    return isLogin ? (
        <>
            <Upload {...props}>
                <Button icon={<UploadOutlined />}>매출 거래처 엑셀파일 업로드</Button>
            </Upload>

            <Divider></Divider>
            <Button onClick={getConvertFile}>데이터 변환</Button><br /><br />
            <Table dataSource={data} loading={loading} columns={sales_clients_columns} size="small" className='intraTable' 
                   scroll={{ y: 400 }}
                       bordered={true} pagination={{ showSizeChanger: false, pageSize: 20 }}
            /><br /><br />
            <Button onClick={writeDB} type='primary'>서버로 데이터 전송</Button>
        </>
    ) : null;
}

export default SalesClients;