import React from 'react';
import { Row, Typography } from 'antd';

import { useRecoilState, useRecoilValue } from 'recoil';
import { getLoginState, loginState, userState } from '../../store/user-store.js';

import Dashboard from './dashboard/dashboard';

const { Text } = Typography;

function Areas() {

    const [{isLogin, isAdmin, isSession}, setLoginState] = useRecoilState(loginState);
    const [{ 
        userId,
        userName,
        userArea,
        userOfficePosition,
    }, setUserState] = useRecoilState(userState);

    return isLogin ? (
        <>
        </>
    ) : null;
}

export default Areas;