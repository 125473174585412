import { useEffect, useState } from 'react';
import { Table, PageHeader } from 'antd';

import { apiCall } from '../../../common-lib/custom-axios';

import { totalUsersList_columns } from '../tableDatas/dashboardDatas';


const CurrentUsersList = () => {

    const [data, setData] = useState([]);

    useEffect(() => {
        getCurrentUsersList();
    }, []);

    const getCurrentUsersList = async () => {
        const res = await apiCall('/dashboard/currentuserslist').then( res => res.data );
        setData(res.data);
        console.log(res);
    
        // 데이터가 없을때 처리
    }

    return (
        <>
            <PageHeader className="site-page-header" title="현재 접속중인 사용자 보기" subTitle="현재 접속되어있는 사용자만 보여줍니다." />
            <p>* 현재 접속자 건수와 접속자 수가 다른 경우 동일 아이디의 중복 접속일 수 있습니다.</p>
            <Table dataSource={data} columns={totalUsersList_columns} size="small" className='intraTable' 
                   bordered={true} pagination={false}/>
        </>
    )
}
export default CurrentUsersList;