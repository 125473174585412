export const sales_clients_columns = [
    {
        title: '코드',
        dataIndex: '0',
        align: 'center',
        key: '0',
        fixed: 'left',
        width: 120,
    },
    {
        title: '상호',
        dataIndex: '1',
        align: 'left',
        key: '1',
        fixed: 'left',
        width: 220,
    },
    {
        title: '사업주',
        dataIndex: '2',
        align: 'center',
        key: '2',
        width: 120,
    },
    {
        title: '사업자번호',
        dataIndex: '3',
        align: 'center',
        key: '3',
        width: 120,
    },
    {
        title: '등록일',
        dataIndex: '5',
        align: 'center',
        key: '5',
        width: 120,
    },
    {
        title: '업소분류',
        dataIndex: '6',
        align: 'center',
        key: '6',
        width: 80,
    },
    {
        title: '과세유형',
        dataIndex: '7',
        align: 'center',
        key: '7',
        width: 80,
    },
    {
        title: '폐업일',
        dataIndex: '8',
        align: 'center',
        key: '8',
        width: 120,
    },
    {
        title: '전화1',
        dataIndex: '9',
        align: 'center',
        key: '9',
        width: 120,
    },
    {
        title: '전화2',
        dataIndex: '10',
        align: 'center',
        key: '10',
        width: 120,
    },
    {
        title: '이메일',
        dataIndex: '12',
        align: 'center',
        key: '12',
        width: 200,
    },
    {
        title: '업태',
        dataIndex: '13',
        align: 'left',
        key: '13',
        width: 120,
    },
    {
        title: '업종',
        dataIndex: '14',
        align: 'left',
        key: '14',
        width: 120,
    },
    {
        title: '주소',
        dataIndex: '15',
        align: 'left',
        key: '15',
        width: 300,
    },
    {
        title: '우편번호',
        dataIndex: '16',
        align: 'center',
        key: '16',
        width: 120,
    },
    {
        title: '전화3',
        dataIndex: '18',
        align: 'center',
        key: '18',
        width: 120,
    },
    {
        title: '팩스번호',
        dataIndex: '19',
        align: 'center',
        key: '19',
        width: 120,
    },
    {
        title: '매출한도',
        dataIndex: '20',
        align: 'center',
        key: '20',
        width: 120,
    },
    {
        title: '미수한도',
        dataIndex: '21',
        align: 'center',
        key: '21',
        width: 120,
    },
    {
        title: '계약일',
        dataIndex: '26',
        align: 'center',
        key: '26',
        width: 120,
    },
    {
        title: '면허일자',
        dataIndex: '27',
        align: 'center',
        key: '27',
        width: 120,
    },
    {
        title: '면허번호',
        dataIndex: '28',
        align: 'center',
        key: '28',
        width: 120,
    },
    {
        title: '업소유형',
        dataIndex: '29',
        align: 'center',
        key: '29',
        width: 120,
    },
    {
        title: '주민등록번호',
        dataIndex: '31',
        align: 'center',
        key: '31',
        width: 120,
    },
    {
        title: '주류카드번호',
        dataIndex: '32',
        align: 'center',
        key: '32',
        width: 120,
    },
    {
        title: '카드예금주',
        dataIndex: '33',
        align: 'center',
        key: '33',
        width: 120,
    },
    {
        title: '카드은행명',
        dataIndex: '34',
        align: 'center',
        key: '34',
        width: 120,
    },
    {
        title: '카드계좌',
        dataIndex: '35',
        align: 'center',
        key: '35',
        width: 120,
    },
    {
        title: '거주지주소',
        dataIndex: '36',
        align: 'left',
        key: '36',
        width: 300,
    },
    {
        title: '최종거래일',
        dataIndex: '38',
        align: 'center',
        key: '38',
        width: 120,
    },
    {
        title: '구분',
        dataIndex: '45',
        align: 'center',
        key: '45',
        width: 120,
    },
    {
        title: '구분',
        dataIndex: '45',
        align: 'center',
        key: '45',
        width: 120,
    },
];

export const buy_clients_columns = [
    {
        title: '코드',
        dataIndex: '0',
        align: 'center',
        key: '0',
        fixed: 'left',
        width: 120,
    },
    {
        title: '상호',
        dataIndex: '1',
        align: 'left',
        key: '1',
        fixed: 'left',
        width: 220,
    },
    {
        title: '대표자',
        dataIndex: '2',
        align: 'center',
        key: '2',
        width: 120,
    },
    {
        title: '사업자번호',
        dataIndex: '3',
        align: 'center',
        key: '3',
        width: 120,
    },
    {
        title: '등록일',
        dataIndex: '4',
        align: 'center',
        key: '4',
        width: 120,
    },
    {
        title: '업태',
        dataIndex: '5',
        align: 'center',
        key: '5',
        width: 80,
    },
    {
        title: '업종',
        dataIndex: '6',
        align: 'center',
        key: '6',
        width: 80,
    },
    {
        title: '전화1',
        dataIndex: '7',
        align: 'center',
        key: '7',
        width: 120,
    },
    {
        title: '전화2',
        dataIndex: '8',
        align: 'center',
        key: '8',
        width: 120,
    },
    {
        title: '폐업일',
        dataIndex: '9',
        align: 'center',
        key: '9',
        width: 120,
    },
    {
        title: '주소',
        dataIndex: '10',
        align: 'center',
        key: '10',
        width: 200,
    },
    {
        title: '이메일1',
        dataIndex: '11',
        align: 'left',
        key: '11',
        width: 120,
    },
    {
        title: '이메일2',
        dataIndex: '12',
        align: 'left',
        key: '12',
        width: 120,
    },
    {
        title: '거래상태',
        dataIndex: '13',
        align: 'left',
        key: '13',
        width: 300,
    },
    {
        title: '비고',
        dataIndex: '14',
        align: 'left',
        key: '14',
        width: 300,
    },
];