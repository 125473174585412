import React, { useRef, useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Row, Typography, Form, Input, Button, Modal } from 'antd';
// import { Button } from "@blueprintjs/core";
import { UserOutlined } from '@ant-design/icons';

import { useRecoilState, useRecoilValue } from 'recoil';
import { loginState, userState } from '../store/user-store';

import { apiLogin } from '../common-lib/custom-axios';

const { Title } = Typography;

function Login() {

    let navigate = useNavigate();

    const userInput = useRef();

    // 시작할때 포커스를 해당 input 폼에서 시작하도록 함
    useLayoutEffect(() => {
        if(userInput.current !== null) userInput.current.focus();
    })

    const [{ isLogin, isAdmin, isSession }, setLoginState] = useRecoilState(loginState);
    const [{ userId,
             userName,
             userArea,
             userOfficePosition,
           }, setUserState] = useRecoilState(userState);

    const onFinish = async (values) => {
        
        const request = await apiLogin.post(`/login`, values).then(res => res.data);

        console.log(request);
        if(request.loginSuccess) {
            // 세션스토리지에 저장
            sessionStorage.setItem('SSID', request.SSID);
            // recoil 상태변수에 상태값들을 저장한다.
            setLoginState({isLogin : request.loginSuccess, isAdmin: request.isAdmin, isSession : request.SSID});
            setUserState({
                userId : request.userId,
                userName : request.userName,
                userArea : request.userArea,
                userOfficePosition : request.userOfficePosition,
            });
            navigate('/');
        }
        else {
            Modal.error({
                title: '로그인 실패',
                content: '아이디나 패스워드가 달라 로그인에 실패하였습니다.', 
                onOk() { userInput.current.focus(); userInput.current.select(); },
            });
        }

    }

    const onFinishFailed = () => {}

    return (
        <div className="Login">
        <div>
          <Row justify="center">
            <img src={`../images/logo-login-basic.png`} align="center" 
                      valign="middle" vspace="80" />
          </Row>
          <Row justify="center">
            <Card className='login'
                  style={{ width : 400, height: 360, margin: 0, borderRadius: "10px", overflow: "hidden", 
                           borderColor: 'rgba(0, 0, 0, 0.2)', backgroundColor: 'rgba(0, 0, 0, 0.2)', boxShadow:'2px 2px 2px 1px rgba(0, 0, 0, 0.4)' }}
                  title={<Title >관리자 접속...</Title>} >
                      <br />
            <Form className='login'
                    name="basic"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 14 }}
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off" >
                <Form.Item
                        label="아이디"
                        name="userid"
                        rules={[{ required: true, message: '반드시 입력해주세요' }]} >
                    <Input placeholder="아이디 입력"
                           suffix={<UserOutlined className="site-form-item-icon" style={{color:'#aaa'}} />}
                           ref={userInput} />
                </Form.Item>
                <br />
                <Form.Item
                        label="비밀번호"
                        name="userpass"
                        rules={[{ required: true, message: '반드시 입력해주세요' }]} >
                    <Input.Password placeholder="비밀번호 입력" />
                </Form.Item>
                <br />
                <Form.Item wrapperCol={{ offset: 4, span: 14 }}>
                    <Button type="primary" htmlType="submit">
                    로 그 인
                    </Button>
                </Form.Item>
            </Form>
            </Card>
          </Row>
        </div>
        </div>
    );
}

export default Login;