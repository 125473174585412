import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function Logout() {

    let navigate = useNavigate();

    useEffect(() => {

        sessionStorage.clear();
        navigate('/login', {replace: true});
        
    }, [])

    return null;
}

export default Logout;