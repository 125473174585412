import axios from 'axios';
import { AXIOS_HEADERS, ADMIN_API_URL, IMG_API_URL } from '../config/config';
import 'antd/dist/antd.css';
import '../index.css';

export const apiLogin = axios.create({
    baseURL : `${ADMIN_API_URL}`,
    headers : AXIOS_HEADERS,
});

export const apiCall = axios.create({
    baseURL : `${ADMIN_API_URL}`,
    headers : AXIOS_HEADERS,
});

export const imageApiCall = axios.create({
    baseURL : `${IMG_API_URL}`,
    headers : AXIOS_HEADERS,
});

// 서버로 api 호출 전에
apiCall.interceptors.request.use( async function (config) {

    const SSID = sessionStorage.getItem('SSID');
    config.headers.Authorization = `Bearer ${SSID}`;

    return config;
})