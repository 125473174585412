import { Popconfirm, Space, Row, Avatar, Switch } from 'antd';
import { EditFilled, DeleteFilled, UserOutlined } from '@ant-design/icons';
import { editUserHandler, delUserHandler } from '../users';

export const groups_columns = [
    {
        title: 'ID',
        dataIndex: 'id',
        align: 'right',
        key: 'id',
    },
    {
        title: '그룹명',
        dataIndex: 'groupName',
        align: 'left',
        key: 'groupName',
    },
    {
        title: '그룹설명',
        dataIndex: 'groupDescription',
        align: 'left',
        key: 'groupDescription',
    },
    {
        title: 'menuId',
        dataIndex: 'menuId',
        align: 'center',
        key: 'menuId',
    },
    {
        title: '메뉴접근권한',
        dataIndex: 'exePermission',
        align: 'center',
        key: 'exePermission',
        render : (_, record) => (
            <Switch checked={record.exePermission} onChange={onSwitchChange} />
        )
    },
    {
        title: '읽기권한',
        dataIndex: 'readPermission',
        align: 'center',
        key: 'readPermission',
        render : (_, record) => (
            <Switch checked={record.readPermission} onChange={onSwitchChange} /> 
        )
    },
    {
        title: '쓰기권한',
        dataIndex: 'writePermission',
        align: 'center',
        key: 'writePermission',
        render : (_, record) => (
            <Switch checked={record.writePermission} onChange={onSwitchChange} />
        )
    },
    {
        title: '수정권한',
        dataIndex: 'updatePermission',
        align: 'center',
        key: 'updatePermission',
        render : (_, record) => (
            <Switch checked={record.updatePermission} onChange={onSwitchChange} /> 
        )
    },
    {
        title: '삭제권한',
        dataIndex: 'deletePermission',
        align: 'center',
        key: 'deletePermission',
        render : (_, record) => (
            <>
            <Switch checked={record.deletePermission} onChange={onSwitchChange} />
            </>
        )
    },
    {
        title: '작업',
        dataIndex: '',
        align: 'center',
        key: '',
        render: (record) => {
            return(
                <Space>
                    <Row justify="space-around" style={{width:50}}>
                    <a><DeleteFilled /></a>
                    </Row>
                </Space>
            )
        }
    },
];

const onSwitchChange = (value) => {
    console.log(value);
}

export const login_log_columns = [
    {
        title: '접속아이디',
        dataIndex: 'userId',
        align: 'left',
        key: 'userId',
    },
    {
        title: '사용자이름',
        dataIndex: 'userName',
        align: 'center',
        key: 'userName',
    },
    {
        title: '접속성공',
        dataIndex: 'isSuccess',
        align: 'center',
        key: 'isSuccess',
        render: isSuccess => {
            if(isSuccess === '성공') isSuccess = <font color='blue'>성공</font>;
            if(isSuccess === '실패') isSuccess = <font color='red'>실패</font>;
            return isSuccess;
        },
        filters: [
            { text: '성공', value: '성공' },
            { text: '실패', value: '실패' },
        ],
        onFilter: (value, record) => record.isSuccess.indexOf(value) === 0,
    },
    {
        title: '접속일자',
        dataIndex: 'logDate',
        align: 'center',
        key: 'logDate',
    },
    {
        title: '접속자 아이피',
        dataIndex: 'remoteIP',
        align: 'center',
        key: 'remoteIP',
    },
    {
        title: '접속위치',
        dataIndex: 'mode',
        align: 'center',
        key: 'mode',
    },
    {
        title: '접속 기기 정보',
        dataIndex: 'userAgent',
        align: 'left',
        key: 'userAgent',
    },
];

export const logs_columns = [
    {
        title: '접속아이디',
        dataIndex: 'userId',
        align: 'left',
        key: 'userId',
    },
    {
        title: '사용자이름',
        dataIndex: 'userName',
        align: 'center',
        key: 'userName',
    },
    {
        title: '접속경로(End Point)',
        dataIndex: 'endPoint',
        align: 'center',
        key: 'endPoint',
    },
    {
        title: '실행SQL',
        dataIndex: 'execSQL',
        align: 'center',
        key: 'execSQL',
    },
    {
        title: '작업 행',
        dataIndex: 'rowId',
        align: 'center',
        key: 'rowId',
    },
    {
        title: 'SQL 실행',
        dataIndex: 'execSuccess',
        align: 'center',
        key: 'execSuccess',
        render: execSuccess => {
            if(execSuccess === '성공') execSuccess = <font color='blue'>성공</font>;
            if(execSuccess === '실패') execSuccess = <font color='red'>실패</font>;
            return execSuccess;
        },
        filters: [
            { text: '성공', value: '성공' },
            { text: '실패', value: '실패' },
        ],
        onFilter: (value, record) => record.execSuccess.indexOf(value) === 0,
    },
    {
        title: '로그일자',
        dataIndex: 'logDate',
        align: 'center',
        key: 'logDate',
    },
    {
        title: '접속아이피',
        dataIndex: 'remoteIP',
        align: 'center',
        key: 'remoteIP',
    },
];

export const users_columns = [
    {
        title: '아이디',
        dataIndex: 'userId',
        align: 'left',
        key: 'userId',
    },
    {
        title: '아바타',
        dataIndex: 'avatar',
        align: 'center',
        key: 'avatar',
        render: (_, record) =>
            record.avatar == "" && record.userName ? (
                <>
                    <Avatar size={24} style={{ backgroundColor: '#36cfc9' }} >{record.userName.substring(1,3)}</Avatar>
                </>
            ) : (
                <>
                    <Avatar size={24} style={{ backgroundColor: '#36cfc9' }} icon={<UserOutlined />}> </Avatar>
                </>
            )
    },
    {
        title: '이름',
        dataIndex: 'userName',
        align: 'center',
        key: 'userName',
    },
    {
        title: '성별',
        dataIndex: 'gender',
        align: 'center',
        key: 'gender',
    },
    {
        title: '관리자유무',
        dataIndex: 'admin',
        align: 'center',
        key: 'admin',
    },
    {
        title: '부 서',
        dataIndex: 'department',
        align: 'center',
        key: 'department',
    },
    {
        title: '직 위',
        dataIndex: 'postPosition',
        align: 'center',
        key: 'postPosition',
    },
    {
        title: '직 책',
        dataIndex: 'onesDuty',
        align: 'center',
        key: 'onesDuty',
    },
    {
        title: '담당구역',
        dataIndex: 'territory',
        align: 'center',
        key: 'territory',
    },
    {
        title: '고용일',
        dataIndex: 'employeeDate',
        align: 'center',
        key: 'employeeDate',
    },
    {
        title: '핸드폰 번호',
        dataIndex: 'mobile',
        align: 'center',
        key: 'mobile',
    },
];



