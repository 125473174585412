import { useEffect, useState } from 'react';
import { Table, PageHeader } from 'antd';

import { apiCall } from '../../../common-lib/custom-axios';

import { PCWebUsersList_columns } from '../tableDatas/dashboardDatas';


const PCWebUsersList = () => {

    const [data, setData] = useState([]);

    useEffect(() => {
        getPCWebUsersList();
    }, []);

    const getPCWebUsersList = async () => {
        const res = await apiCall('/dashboard/pcwebuserslist').then( res => res.data );
        setData(res.data);
        console.log(res);
    
        // 데이터가 없을때 처리
    }

    return (
        <>
            <PageHeader className="site-page-header" title="PC에서 웹화면으로 접속한 경우" subTitle="로그인에 성공한 사용자만 나옵니다." />
            <Table dataSource={data} columns={PCWebUsersList_columns} size="small" className='intraTable' 
                   bordered={true} pagination={{ showSizeChanger: false, pageSize: 12 }}/>
        </>
    )
}
export default PCWebUsersList;