import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Tabs, Table, PageHeader, Row, Drawer, Button,  Space, Divider, Card, Col, Avatar, Popconfirm } from 'antd';
import { AppstoreFilled, UsergroupAddOutlined, SaveOutlined, EditFilled, DeleteFilled } from '@ant-design/icons';

import { errorSelector, useRecoilState, useRecoilValue } from 'recoil';
import { getLoginState, loginState, userState } from '../../store/user-store.js';

import { useForm, Controller } from 'react-hook-form';
import InputMask from 'react-input-mask';

import { apiCall } from '../../common-lib/custom-axios';
import { openErrorModal, openNotification } from '../../common-lib/common';

import { users_columns } from './tableDatas/tabledatas';
//import './../../css/intratable.css';
import './../../css/input.css';

function Users() {

    const { TabPane } = Tabs;
    const { Meta } = Card;
    const { register, setValue, getValues, handleSubmit, reset, control, formState: {errors} } = useForm();

    let navigate = useNavigate();

    const [{isLogin, isAdmin, isSession}, setLoginState] = useRecoilState(loginState);
    const [{ 
        userId,
        userName,
        userArea,
        userOfficePosition,
    }, setUserState] = useRecoilState(userState);

    const [ usersData, setUsersData ] = useState([]);
    const [ usersData2, setUsersData2 ] = useState([]);
    const [ showDrawer, setShowDrawer ] = useState(false);
    const [ drawerMode, setDrawerMode ] = useState('view');
    const [ drawerTitle, setDrawerTitle ] = useState('인트라넷 사용자 정보 보기');
    const [ currentRecord, setCurrentRecord ] = useState({});

    const operationCell = 
        {
            title: '작업',
            dataIndex: '',
            align: 'center',
            render: (_, record) => 
                (
                    <>
                        <Space size={24}>
                        <Popconfirm title="선택한 행을 수정하시겠습니까 ?"
                                onConfirm={(e) => {e.stopPropagation(); console.log('액션시작'); editUserHandler(record);}}
                                onCancel={(e) => e.stopPropagation()}
                                placement="topRight"
                                okText="예" cancelText="아니오">
                            <a onClick={e => e.stopPropagation()}><EditFilled style={{color:'#ff7875', fontSize: '18px'}} /></a>
                        </Popconfirm>
    
                        <Popconfirm title="선택한 행을 삭제하시겠습니까 ?"
                                onConfirm={(e) => {e.stopPropagation(); delUserHandler(record.id)}}
                                onCancel={(e) => e.stopPropagation()}
                                placement="topRight"
                                okText="예" cancelText="아니오">
                            <a onClick={e => {e.stopPropagation()}}><DeleteFilled style={{color:'#ff7875', fontSize: '18px'}} /></a>
                        </Popconfirm>
                        </Space>
                    </>
                )
        };
    

            
    useEffect(() => {
        if(users_columns.length === 12 )
            users_columns.pop(null);

        if(users_columns.length < 12)
            users_columns.push( operationCell );

        getUsersData();
    }, []);

    const getUsersData = async () => {
        const res = await apiCall.get('/users').then(res => res.data);
 
        if(res.isSuccess) {
            openNotification('사용자 목록', res.msg.long);
            setUsersData(res.data);
        }
        else {
            openErrorModal('사용자 목록 불러오기 실패', res.msg.long);
        }
    }
 
    const editUserHandler = (record) => {

        console.log(record)
        setCurrentRecord(record);
        reset({
            userId : record.userId,
            userName : record.userName,
            admin : record.admin,
            gender : record.gender,
            birthday : record.birthday,
            mobile : record.mobile,
            department : record.department,
            territory : record.territory,
            onesDuty : record.onesDuty,
            postPosition : record.postPosition,
            employeeDate : record.employeeDate,
            retireDate : record.retireDate,
            pay : record.pay,
        });
        setDrawerMode('edit');
        setDrawerTitle('인트라넷 사용자 정보 수정');
        setShowDrawer(true);
        console.log('액션끝');
        
    }

    const delUserHandler = (id) => {
    
        console.log(id);
    
    }    

    const TitleHead = (
        <>
            <Row justify='center' align='middle'><AppstoreFilled />&nbsp;<div>사용자 관리</div></Row>
        </>
    );

    // 눌린 탭의 key 값을 가져온다.
    const checkHandle = (e) => {
        console.log(e);
    }

    // 사용자추가 Button
    const addUser = () => {
        console.log('사용자추가');
        setDrawerMode('add');
        setDrawerTitle('인트라넷 사용자 추가');
        //form.resetFields();
        setCurrentRecord({});
        reset({
            userId : '',
            userName : '',
            admin : '',
            gender : '',
            birthday : '',
            mobile : '',
            department : '',
            territory : '',
            onesDuty : '',
            postPosition : '',
            employeeDate : '',
            retireDate : '',
            pay : '',
        })
        setShowDrawer(true);
        //console.log(currentRecord);
    }

    const handleOnRow = (record) => {
        //e.stopPropagation();
        console.log(record)
        setCurrentRecord(record);
        setDrawerMode('view');
        setDrawerTitle('인트라넷 사용자 정보 보기');
        setShowDrawer(true);
    }

    // const drawerOKHandler = () => {
    //     setShowDrawer(false);
    // }

    const drawerCancelHandler = () => {
        setShowDrawer(false);
    }

    const drawerCloseHandler = () => {
        setShowDrawer(false);
    }

    const onSubmit = async (values) => {

        //console.log(values);

        const res = await callAPI(values);

        // POST api call
        // 서버에서 아이디 중복체크 할것.
        console.log(res);
 
        if(res.isSuccess) {
            openNotification('사용자 등록 성공', res.msg.long);
            setUsersData(res.data);
        }
        else {
            openErrorModal('사용자 등록 실패', res.msg.long);
        }
    }

    const callAPI = (data) => {

        if(drawerMode === 'add') {
            return apiCall.post('/users').then(res => res.data );
        }
        else {
            return apiCall.put('/users', {data}).then(res => res.data);
        }
    }

    const DrawerAddEditUser = (
        <div>
            <form onSubmit={handleSubmit(onSubmit)} className="mycustom">
                {drawerMode === 'add' ? 
                    <>
                        {errors.userId && <div style={{color:'#40a9ff'}}>아이디는 반드시 입력해주세요.</div>}
                        <input {...register('userId', { required: true })} placeholder="아이디 (이메일주소)" />
                    </> :
                    <>
                        {errors.userId && <div style={{color:'#40a9ff'}}>아이디는 반드시 입력해주세요.</div>}
                        <input {...register('userId', { required: true })} placeholder="아이디 (이메일주소)" disabled />
                    </>
                }
                {drawerMode === 'add' &&  
                    <>
                    {errors.userPassword1 && <div style={{color:'#40a9ff'}}>{errors.userPassword1.message}</div>}
                    <input {...register('userPassword1', 
                        { required: true, minLength: { value: 8, message: "8자리이상의 비밀번호가 필요합니다."} })} type="password" placeholder='비밀번호' />
                    {errors.userPassword2 && <div style={{color:'#40a9ff'}}>{errors.userPassword2.message}</div>}
                    <input {...register('userPassword2', {
                        required : true, validate: value => value === getValues('userPassword1') || '비밀번호가 일치하지 않습니다. 다시 입력해주세요.' })} type="password" placeholder='비밀번호 재확인' />
                    </>
                }
                <input {...register('userName', { required: true })} placeholder="이름" />
                <Controller control={control} name='birthday' render={({ field: { onChange, onBlur, value, ref}}) => (
                    <InputMask mask='9999-99-99' onChange={onChange} placeholder="생년월일 YYYY-MM-DD"  value={value} />
                )} />
                {/* <input {...register('birthday', { required: true })} placeholder="생년월일 YYYY-MM-DD" /> */}
                <Row justify='space-between'>
                    <select {...register('admin', { required: true })} style={{width:150}}>
                        <option value="" disabled selected>관리자유무</option>
                        <option value="사용자">사용자</option>
                        <option value="관리자">관리자</option>
                    </select>
                    <select {...register('gender', { required: true })} style={{width:150}}>
                        <option value="" disabled selected>성별</option>
                        <option value="남성">남성</option>
                        <option value="여성">여성</option>
                    </select>
                </Row>
                <Row justify='space-between'>
                    <select {...register('department', { required: true })} style={{width:150}}>
                        <option value="" disabled selected>소속부서</option>
                        <option value="총괄팀">총괄팀</option>
                        <option value="관리팀">관리팀</option>
                        <option value="영업팀">영업팀</option>
                        <option value="판매팀">판매팀</option>
                    </select>
                    <select {...register('territory', { required: true })} style={{width:150}}>
                        <option value="" disabled selected>담당구역</option>
                        <option value="신제주">신제주</option>
                        <option value="구제주">구제주</option>
                        <option value="시외 동">시외 동</option>
                        <option value="시외 서">시외 서</option>
                        <option value="양주">양주</option>
                        <option value="전체">전체</option>
                    </select>
                </Row>
                <Row justify='space-between'>
                    <select {...register('postPosition', { required: true })} style={{width:150}}>
                        <option value="" disabled selected>직위</option>
                        <option value="회장">회장</option>
                        <option value="부회장">부회장</option>
                        <option value="사장">사장</option>
                        <option value="부사장">부사장</option>
                        <option value="상무">상무</option>
                        <option value="이사">이사</option>
                        <option value="부장">부장</option>
                        <option value="차장">차장</option>
                        <option value="과장">과장</option>
                        <option value="대리">대리</option>
                        <option value="주임">주임</option>
                        <option value="사원">사원</option>
                    </select>
                    <select {...register('onesDuty', { required: true })} style={{width:150}}>
                        <option value="" disabled selected>직책</option>
                        <option value="대표이사">대표이사</option>
                        <option value="업무부장">업무부장</option>
                        <option value="관리실장">관리실장</option>
                        <option value="파트장">파트장</option>
                        <option value="판매팀장">판매팀장</option>
                    </select>
                </Row>
                <input {...register('mobile', { required: true })} placeholder="핸드폰 000-0000-0000" />
                <input {...register('employeeDate')} placeholder="출근일 YYYY-MM-DD" />
                { drawerMode === 'edit' && 
                    <input {...register('retireDate', { required: true })} placeholder="퇴사일 YYYY-MM-DD" />
                }
                <input {...register('pay')} placeholder="기본급" />
            </form>
            <Divider></Divider>
            <Row justify='center'>
            <Space>
                <Button onClick={handleSubmit(onSubmit)} icon={<SaveOutlined />} type="primary" danger style={{borderRadius:20, color:'white', width:120}}>저&nbsp;&nbsp;장</Button>
                <Button onClick={drawerCancelHandler} style={{borderRadius:20}} >취소</Button>
            </Space>
            </Row>
        </div>
    );

    const DrawerViewUser = (
        <>
            <Card title="사용자 개인정보 보기" style={{borderRadius:30, lineHeight:3}}>
                <Row justify='center'>
                    <Meta
                        avatar={<></>}
                        title={`${currentRecord.userName} (${currentRecord.userId})`} /><br />
                    <Col span={10} align='right' style={{color:'#666'}}>생년월일 : &nbsp;</Col>
                    <Col span={14}>{currentRecord.birthday}</Col>
                </Row>
                <Row>
                    <Col span={10} align='right' style={{color:'#666'}}>성별 : &nbsp;</Col>
                    <Col span={14}>{currentRecord.gender}</Col>
                </Row>
                <Row>
                    <Col span={10} align='right' style={{color:'#666'}}>관리자유무 : &nbsp;</Col>
                    <Col span={14}>{currentRecord.admin}</Col>
                </Row>
                <Row>
                    <Col span={10} align='right' style={{color:'#666'}}>부서 : &nbsp;</Col>
                    <Col span={14}>{currentRecord.department}</Col>
                </Row>
                <Row>
                    <Col span={10} align='right' style={{color:'#666'}}>직책 : &nbsp;</Col>
                    <Col span={14}>{currentRecord.onesDuty}</Col>
                </Row>
                <Row>
                    <Col span={10} align='right' style={{color:'#666'}}>직위 : &nbsp;</Col>
                    <Col span={14}>{currentRecord.postPosition}</Col>
                </Row>
                <Row>
                    <Col span={10} align='right' style={{color:'#666'}}>담당구역 : &nbsp;</Col>
                    <Col span={14}>{currentRecord.territory}</Col>
                </Row>
                <Row>
                    <Col span={10} align='right' style={{color:'#666'}}>입사일 : &nbsp;</Col>
                    <Col span={14}>{currentRecord.employeeDate}</Col>
                </Row>
                <Row>
                    <Col span={10} align='right' style={{color:'#666'}}>핸드폰 : &nbsp;</Col>
                    <Col span={14}>{currentRecord.mobile}</Col>
                </Row>
            </Card>
            {/* <Divider></Divider> */}<br/>
            <Row justify='center'>
                <Button onClick={drawerCancelHandler} style={{borderRadius:20}} >돌아가기</Button>
            </Row>
        </>
    );
    
    return isLogin ? (
        <>
          <Tabs tabPosition='top' onChange={checkHandle}>
            <TabPane tab="근속 중 사용자" key="1">
                <PageHeader className="site-page-header" title={TitleHead} subTitle="사용자들에 대한 추가 / 수정 / 삭제 기능 등의 관리가 가능합니다."
                            extra={[
                                <Button key="1" type="primary" icon={<UsergroupAddOutlined style={{fontSize:'16pt'}} />} onClick={addUser} style={{borderRadius:20}}>사용자추가</Button>,
                            ]} ></PageHeader>
                <Table size="small" className='intraTable'
                       dataSource={usersData} columns={users_columns} bordered={true} pagination={{ pageSize: 12 }}
                       onRow={(record) => { return {
                           onClick: () => {handleOnRow(record)},
                       }; }}
                       />
            </TabPane>
            <TabPane tab="퇴사자" key="2">
                <PageHeader className="site-page-header" title={TitleHead} subTitle="관리자 화면으로 로그인한 기록을 관리합니다." />
                <Table dataSource={usersData2} columns={users_columns} size="small" className='intraTable' 
                       bordered={true} pagination={{ showSizeChanger: false, pageSize: 12 }}/>
            </TabPane>
          </Tabs>

          <Drawer className="mycustom" title={drawerTitle} visible={showDrawer} onClose={drawerCloseHandler}
                  placement='right' >
            {drawerMode === 'view' ? DrawerViewUser : DrawerAddEditUser}
          </Drawer>
        </>
    ) : (<>{() => {sessionStorage.clear(); 
                   openErrorModal('인증 시간 만료!', '인증 시간이 만료되어 로그인 화면으로 이동합니다.'); 
                   navigate('/'); 
        }}</>);
}

export default Users;